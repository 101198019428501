<template>
  <div class="flex flex-col gap-2">
    <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
      <TabInsights
        :title="`Number of Treatments per Treatment Area (${treatmentType.toUpperCase()})`"
        :hasQuickInsights="false"
      >
        <TreatmentAreas
          :type="treatmentType"
          :selected="selected"
          :chartDataProps="chartData"
          @updateChartData="chartData = $event"
        />
      </TabInsights>
    </vs-row>

    <!-- <div
      class="flex mt-2" style="justify-content: flex-end;">
      <vs-button style="padding:5px 15px;" @click="handleExportToCSV">
        Export
      </vs-button>
    </div> -->
    <vs-table
      v-if="chartData && chartData.labels"
      max-items="10"
      pagination
      noDataText="No Data Available"
      :data="chartData.labels"
    >
      <template slot="thead">
        <vs-th
          style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 50vw;
            max-width: 50vw;
          "
          >CATEGORY</vs-th
        >
        <vs-td   style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 50vw;
            font-weight: bold;
            font-size: .85rem;
            max-width: 50vw;
          " :key="index" v-for="(product, index) in chartData.datasets">
          {{ product.label.toUpperCase()}}
        </vs-td>
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >TOTAL TREATMENTS</vs-th
        >
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="`per-area-${indextr}`" v-for="(tr, indextr) in data">
          <vs-td :data="tr">
            {{ tr }}
          </vs-td>
          <vs-td :key="index" v-for="(product, index) in chartData.datasets">
            {{ product.data[indextr] }}
          </vs-td>
          <vs-td :data="tr">
            {{ getTotalTreatments(tr) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TreatmentAreas from "../../components/insights/treatments/TreatmentAreas.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from '@/assets/utils'
import moment from 'moment';

export default {
  components: {
    TreatmentAreas,
    TabInsights,
    Sidebar,
  },
  data() {
    return {
      selected: {
        postcode: [],
        suburb: [],
        brand: [],
        product: [],
        clinic: [],
        org: [],
        state: [],
        nurse_id: [],
        frequency: 30,
      },
      chartData: {},
      treatmentType: "",
      urlType: "",
      orgId: "",
    };
  },
  methods: {
    ...mapActions("analytics", ["fetchTreatmentAreas"]),
    getTreatmentAreas() {
      this.$vs.loading();
      let payload = {
        treatment_product_id: this.selected.product,
        post_code: this.selected.postcode,
        frequency: this.selected.frequency,
        brand_id: this.selected.brand,
        state_abbreviation: this.selected.state,
        suburb_name: this.selected.suburb,
        clinic_id: this.selected.clinic,
        type: this.treatmentType,
        nurse_id: this.selected.nurse_id,
        is_nurse: true,
        org_id: this.selected.org,
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org_id: [this.orgId],
        }
      }
      this.fetchTreatmentAreas(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("# ERR: ", err.message);
          this.$vs.loading.close();
        });
    },
    getTotalTreatments(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = `number_of_treatments_per_treatment_area(${this.treatmentType})_`
      csv += "CATEGORY, TOTAL TREATMENTS\n";

      this.chartData.labels.forEach(row => {
        csv += `${row},${this.getTotalTreatments(row)}\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
    }
  },
  watch: {
    $route:  {
      handler(newVal){
        if(["OrgOwnerInsightsTreatmentByNurseFiller"
        ].includes(newVal.name)){
          this.treatmentType = "filler";
        }else {
          this.treatmentType = "toxin";
        }
        setTimeout(() => {
          this.getTreatmentAreas();
        }, 250);
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.getTreatmentAreas();
      },
      deep: true,
    },
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.selected.brand = userType.brands;
    }
    // this.getTreatmentAreas();
  }
};
</script>
